<template>
  <div>
    <v-col class="cover">
      <div class="action-div">
        <Logo />
        <h2>
          Features
        </h2>
      </div>
    </v-col>
    <div class="page-container"
         style="margin-bottom: 100px"
    >
      <v-row v-for="(item, index) in items" :id="item.anchor"
             :key="item.title"
             class="mt-12"
             :class="{'flex-row-reverse': index % 2 !== 1}"
      >
        <v-col class="d-flex"
               :class="{'justify-end': index % 2 !== 1}"
               md="6" cols="12"
        >
          <img
            v-if="item.img"
            :src="require(`@/assets/images/features/${item.img}.jpg`)"
            class="img-screen"
          >
        </v-col>
        <v-col md="6" cols="12" class="text-left">
          <h2 class="mb-8">
            {{ item.title }}
          </h2>
          <div
            v-for="(itemText, textIndex) in item.text"
            :key="textIndex"
          >
            <ul v-if="typeof itemText === 'object'">
              <p class="font-weight-bold mb-1">
                {{ itemText.title }}
              </p>
              <li
                v-for="(itemList, itemListIndex) in itemText.list"
                :key="itemListIndex"
                v-html="itemList"
              />
            </ul>
            <p
              v-else
              v-html="itemText"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
  name: "Features",
  components: {
    Logo,
  },
  props: {
    anchor: {
      type: String,
      default: 'projects',
    },
  },
  data: () => ({
    items: [
      { 
        title: 'Projects',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
          do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.`
        ],
        img: 'projects',
        anchor: 'projects',
      },
      {
        title: 'Dashboard',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
          enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
          in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum.`,
          {
            title: 'Lorem ipsum dolor sit amet:',
            list: [
              'consectetur adipisicing elit, sed do eiusmod tempor',
              'incididunt ut labore et dolore magna aliqua',
              'enim ad minim veniam',
              'quis nostrud exercitation ullamco'
            ],
          },
        ],
        img: 'dashboard',
        anchor: 'dashboard',
      },
      {
        title: 'Control Room',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
          enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor 
          in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum.`,

          `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
          enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
          in reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui officia deserunt mollit anim
          id est laborum.`,
        ],
        img: 'control-room',
        anchor: 'control-room',
      },
      {
        title: 'Areas',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
          do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat. `,
        ],
        img: 'areas',
        anchor: 'areas',
      },
      {
        title: 'Scope of works',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat. Duis aute
          irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat
          cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.`,
        ],
        img: 'scope-of-works',
        anchor: 'scope-of-works',
      },
      {
        title: 'Workflows',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. `,
        ],
        img: 'workflows',
        anchor: 'workflows',
      },
      {
        title: 'Etendering',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia
          deserunt mollit anim id est laborum.`,
        ],
        img: 'etendering',
        anchor: 'etendering',
      },
      {
        title: 'Payments',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. `,
        ],
        img: 'empty',
        anchor: 'payments',
      },
      {
        title: 'Directory',
        text: [
          `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. `,
        ],
        img: 'empty',
        anchor: 'directory',
      },
    ]
  })
};
</script>